import NavbarSection from "../components/Navbar";
import '../pages/pages.css'
// import HomeImage from '../assests/homeimage.jpeg'
import axios from "axios";


import { HelmetProvider, Helmet } from 'react-helmet-async';
import Star from '../assests/Star.png'

import { useState, useEffect } from "react";

import btnArrow from '../assests/inputbtnarrow.png'

import RedRanking from '../assests/redranking.png'

import HomeRoom from '../assests/homeroom.png'

import React, { useRef } from 'react';
import HomeProfessional1 from '../assests/professional1.png'
import HomeProfessional2 from '../assests/professional2.png'
import HomeProfessional3 from '../assests/professional3.png'
import ProLogo from '../assests/prologo.png'
import People4 from '../assests/4people.png'
import World from '../assests/World.png'
import Screw from '../assests/screw.png'
import Alarm from '../assests/Alarm.png'
import Search from '../assests/search.png'
import IdCard from '../assests/id card.png'
import LessColourStar from '../assests/Star 10.png'
import InVoice from '../assests/Invoice.png'
import Graph from '../assests/Graph.png'
import Dollar from '../assests/cash.png'
import Card from '../assests/Card.png'

import Slider3 from '../assests/slider3.png'


import Slider4 from '../assests/homerankingimageman.png'
import Slider5 from '../assests/slider5.png'
import SelfEmployed from '../assests/selfemployed.png'
import Company from '../assests/company.png'



import FooterSection from "../components/Footer";
import Hero from "../homecomponents/Hero";
import HomeUserInput from "../homecomponents/HomeUserInput";
import ServiceMan from "../homecomponents/ServiceMan";
import Homephone from "../homecomponents/Homephone";
import Playbuttons from "../homecomponents/Playbuttons";
import Homelisting from "../homecomponents/Homelisting";
import Candidates from "../homecomponents/Candidates";
import Professional from "../homecomponents/Professional";
import Contract from "../homecomponents/Contract";
import Developers from "../homecomponents/Developers";
import Users from "../homecomponents/Users";
import Endwords from "../homecomponents/Endwords";
import Design from "../homecomponents/righty";
import CookiesModal from "../homecomponents/CookiesModal"; // Import the modal

import CenteredLogoWithParagraphs from "../homecomponents/CenteredLogoWithParagraphs ";
function HomePage() {
  const [user, setUser] = useState(null); // State to store the user
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(null); // Error state

  // Fetch user session on page load
  useEffect(() => {
    const validateToken = async () => {
      try {
        // Make Axios call to validate-token.php
        const response = await axios.get("https://btobjob.com/validate_token.php", {
          withCredentials: true, // Include cookies in the request
        });
        setUser(response.data.user); // Set the user data if validation is successful
        setLoading(false); // Stop loading
      } catch (err) {
        console.error("Token validation failed:", err);
        setUser(null); // Clear user data on failure
        setLoading(false); // Stop loading
     
      }
    };

    validateToken(); // Call the function
  }, []);


  if (loading) {
    // Display a loading message while validating the token
    return <div>Loading...</div>;
  }

  if (error) {
    // Display an error message if validation fails
    return <div className="text-red-500 text-center mt-4">{error}</div>;
  }

  return (
    <>
    <HelmetProvider>
         <Helmet>
                <title>BtoB Job - Connectez avec des professionnels</title>
                <meta property="og:title" content="BtoB Job - Connectez avec des professionnels" />
                <meta property="og:description" content="La plateforme de mise en relation pour toutes les prestations de travaux /service et locations / ventes de matériel." />
                <meta property="og:image" content="https://btobjob.com/logo.png" />
                <meta property="og:url" content="https://btobjob.com/" />
                <meta property="og:type" content="website" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="BtoBJob - Connectez avec des professionnels" />
                <meta name="twitter:description" content="La plateforme de mise en relation pour toutes les prestations de travaux /service et locations / ventes de matériel." />
                <meta name="twitter:image" content="https://btobjob.com/logo.png" />
            </Helmet>
            </HelmetProvider>
    <div className=" bg-[#fff]">
   
      <NavbarSection />
      <div className="">
        <CenteredLogoWithParagraphs/>
        {/* <Hero /> */}
        {/* <Playbuttons/> */}
       
        <Users/>
        <Homelisting/>
        <Endwords />
        <Professional/>
        <CookiesModal />
        <Contract/>
        <Developers/>
        
        <Homephone/>
        {/* <HomeUserInput/> */}
        {/* <ServiceMan/> */}
  
       
        
        {/* <Candidates/> */}
       
      
        
       
      </div>
      <FooterSection />

    </div>
    </>
  )
}

export default HomePage; 