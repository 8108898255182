import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaArrowLeft } from "react-icons/fa";
import { Link } from 'react-router-dom';
import NavbarSection from "../components/Navbar";
import { useNavigate } from "react-router-dom";
import FooterSection from "../components/Footer";
import LOGO2 from '../assests/ASTUCES.png';
import { HelmetProvider, Helmet } from 'react-helmet-async';
const BlogPage = () => {
    const [posts, setPosts] = useState([]);
    const [categories, setCategories] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [selectedCategory, setSelectedCategory] = useState('');
    const postsPerPage = 5;
    const navigate = useNavigate();

    useEffect(() => {
        const fetchBlogData = async () => {
            try {
                const response = await axios.get(
                    `https://btobjob.com/get_blog_posts.php`, {
                        params: {
                            page: currentPage,
                            limit: postsPerPage,
                            category: selectedCategory,
                        }
                    }
                );
                setPosts(response.data.posts || []);
                setTotalPages(response.data.totalPages || 1);
                setCategories(response.data.categories || []);
            } catch (error) {
                console.error("Error fetching blog posts:", error);
            }
        };
        fetchBlogData();
    }, [currentPage, selectedCategory]);

    const handlePageChange = (page) => {
        if (page >= 1 && page <= totalPages) {
            setCurrentPage(page);
        }
    };

    const handleCategoryChange = (category) => {
        setSelectedCategory(category);
        setCurrentPage(1); // Reset to the first page for the selected category
    };

    return (
        <>
                    <HelmetProvider>
                        <Helmet>
                <title>Blog | BtoBJob</title>
                <meta
                    name="description"
                    content="Découvrez les derniers articles et conseils sur la sous-traitance, la location de matériel, et bien plus encore sur notre blog."
                />
                <meta property="og:title" content="Blog | BtoBJob" />
                <meta
                    property="og:description"
                    content="Restez informé avec notre blog dédié à la sous-traitance, la location de matériel, et les tendances professionnelles."
                />
                <meta property="og:image" content="https://btobjob.com/logo.png" />
                <meta property="og:url" content="https://btobjob.com/blog" />
                <meta property="og:type" content="website" />
                <meta name="twitter:title" content="Blog | BtoBJob" />
                <meta
                    name="twitter:description"
                    content="Consultez notre blog pour des conseils, des astuces, et des articles informatifs sur la sous-traitance et les collaborations professionnelles."
                />
                <meta name="twitter:image" content="https://btobjob.com/logo.png" />
                <meta name="twitter:card" content="summary_large_image" />
                </Helmet>
            </HelmetProvider>
            <NavbarSection />
            <div className="container mx-auto px-4 py-8 mt-5">
            <button
                    onClick={() => navigate('/profile/viewprofile')}
                    className="mb-6 px-4 py-2 bg-orange-500 text-white rounded hover:bg-orange-600"
                >
                    <FaArrowLeft className="inline mr-2" /> Retour
                </button>
                <div className="flex justify-between items-center mb-8 p-4 rounded-3xl shadow-lg">
                    <header className="text-center flex-1">
                        <div className="flex flex-col justify-center items-center mb-8 p-4 rounded-3xl">
                            <img
                                src={LOGO2}
                                alt="Logo"
                                className="h-64 w-auto object-contain mb-4"
                            />
                        </div>
                    </header>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-10">
                    {/* Main Blog Content */}
                    <section className="col-span-2">
                        {posts.map(post => (
                            <BlogPost key={post.id} post={post} />
                        ))}

                        {/* Pagination */}
                        <div className="flex justify-center mt-10">
                            <button
                                className="px-4 py-2 mx-1 bg-gray-200 text-gray-700 rounded"
                                onClick={() => handlePageChange(currentPage - 1)}
                                disabled={currentPage === 1}
                            >
                                Précédent
                            </button>
                            <span className="px-4 py-2 text-gray-700">Page {currentPage} sur {totalPages}</span>
                            <button
                                className="px-4 py-2 mx-1 bg-gray-200 text-gray-700 rounded"
                                onClick={() => handlePageChange(currentPage + 1)}
                                disabled={currentPage === totalPages}
                            >
                                Suivant
                            </button>
                        </div>
                    </section>

                    {/* Sidebar */}
                    <aside className="bg-gray-100 p-6 rounded-lg shadow-md">
    <h2 className="text-xl font-semibold mb-4">Catégories</h2>
    <ul className="list-none m-0 p-0">
        {categories.map((category, index) => (
            <li key={index} className="mb-2">
                <button
                    onClick={() => handleCategoryChange(category)}
                    className={`text-left w-full text-lime-600 hover:underline ${selectedCategory === category ? 'font-bold' : ''}`}
                    style={{ overflowWrap: 'break-word' }}
                >
                    {category}
                </button>
            </li>
        ))}
    </ul>

    <h2 className="text-xl font-semibold mt-8 mb-4">Articles récents</h2>
    <ul>
        {posts.slice(0, 5).map((post) => (
            <li key={post.id} className="mb-2">
                <Link to={`/BlogDetail/${post.id}`} className="text-lime-600 hover:underline">
                    {post.title}
                </Link>
            </li>
        ))}
    </ul>
</aside>

                </div>
            </div>
            <FooterSection />
        </>
    );
};

const BlogPost = ({ post }) => {
    const truncateText = (text, maxLength) => {
        if (text.length > maxLength) {
            return `${text.substring(0, maxLength)}...`;
        }
        return text;
    };

    return (
        <div className="bg-white p-6 rounded-lg shadow-md mb-8">
            <h2 className="text-2xl font-semibold mb-2">{post.title}</h2>
            <p className="text-gray-600 mb-4">{new Date(post.created_at).toLocaleDateString()}</p>
            <p className="text-gray-800">{truncateText(post.description, 150)}</p>
            <Link to={`/BlogDetail/${post.id}`} className="text-lime-600 hover:underline mt-4 inline-block">Lire la suite</Link>
        </div>
    );
};

export default BlogPage;
